// @flow

import React from 'react';
import type { Row, IframeContent } from '../types';

const Iframe = (row: Row<IframeContent>) => {
  if (row.content.length < 1) { return null; }
  const id = (new URL(window.location.href)).searchParams.get('id');
  const name = (new URL(window.location.href)).searchParams.get('name');
  const iframe = row.content[0];
  const url = (new URL(iframe.url));
  if (id !== null) { url.searchParams.append('id', id); }
  if (name !== null) { url.searchParams.append('name', name); }
  return (
    <div className="row">
      <iframe style={{maxHeight: "100vh"}} height={iframe.height} src={url} width="100%" allowFullScreen={true} />
    </div>
  );
};

export default Iframe;
