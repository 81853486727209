// @flow
import React from 'react';
import type { InputFieldType } from './types';
import styles from './styles/ContactForm.module.scss';

const InputField = (props: InputFieldType): any => {
  const { labelName, ...rest } = props;
  return (
    <label htmlFor={props.name} {...rest}>
      {props.labelName}
      <input {...rest} className={styles.input_text} type="text" />
    </label>
  );
};

export default InputField;
