// @flow

import React from 'react';
import type { Option as OptionType, Question } from './types';
import radioOn from '../Subsite/images/radio-on.png';
import radioOff from '../Subsite/images/radio-off.png';

type Props = {
  option: OptionType,
  question: Question,
  onSelect: Function,
};

const Option = ({ option, question, onSelect }: Props) => {
  const img = option.selected ? radioOn : radioOff;
  return (
    <div className="u-margin-top-05">
      <div className="poll-option" onClick={() => onSelect(question, option)}>
        <div className="poll-option__img"><img alt="" src={img} /></div>
        <p className="poll-option__text">{option.title}</p>
      </div>
    </div>
  );
};

export default Option;
