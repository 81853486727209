// @flow

import React from 'react';
import $ from 'jquery';
import type { Row, VideoContent } from './types';
import PlayerContainer from '../Player';
import AgeRatings from '../PlaylistInfoPlayer/AgeRatings';

type Props = Row<VideoContent>;

const Player = ({ content }: Props): any => {
  if (content.length < 1) { return null; }
  const video = content[0];
  return (
    <div className="row">
      <div className="row__block">
        <div className="player__wrapper">
          <div className="player__player">
            <div className="player">
              <div className="player__frame">
                <PlayerContainer playerTokenPayload={video.playerTokenPayload} mid={video.mid} lazy={true} />
              </div>
            </div>
          </div>
          <div className="player__info-body">
            <div className="player__ratings">
              <AgeRatings {...video} />
            </div>
            <p className="player__title">{video.title}</p>
            <p className="player__description">{video.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Player;
