// @flow

/* Functions for working with rows of data. */
import Highlight from '../Containers/Highlight';
import SingleRow from '../Containers/SingleRow';
import DoubleRow from '../Containers/DoubleRow';
import DoubleBlock from '../Containers/DoubleBlock';
import Poll from '../Blocks/Poll';
import CTA from '../Cta';
import ImageLink from '../ImageLink';
import Player from '../Player';
import FragmentsContainer from '../Containers/FragmentsContainer';
import PhotoGallery from '../Blocks/PhotoGallery';
import TextBlock from '../Blocks/TextBlock';
import QuizBlock from '../Blocks/QuizBlock';
import Iframe from '../Blocks/Iframe';
import ContactForm from '../Blocks/ContactForm';
import InstagramEmbed from '../Blocks/InstagramEmbed';
import PodcastBlock from '../Blocks/PodcastBlock'
import type { Row } from '../types';

export const showTitle = (props: Row<any>): boolean => (
  props.content.length > 0
);

export const components = {
  Article: CTA,
  ContactForm,
  DoubleBlock,
  DoubleRow,
  Fragments: FragmentsContainer,
  Highlight,
  Iframe,
  ImageLink,
  InstagramEmbed,
  PhotoGallery,
  Player,
  PodcastBlock,
  Poll,
  QuizBlock,
  SingleRow,
  TextBlock,
};
