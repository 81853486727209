// @flow

import React from 'react';
import Option from './Option';
import type { Question as QuestionType } from './types';

type Props = {
  question: QuestionType,
  onSelect: Function,
  onNextQuestion: Function,
  isFirstQuestion: boolean,
  isLastQuestion: boolean,
  questionIndex: number,
  questionCount: number,
};

const Question = (props: Props) => {
  const { question } = props;

  return (
    <div>
      <div className="quiz__box">
        <div className="u-margin-top heading quiz__title heading--xxs">
          Vraag {props.questionIndex + 1} <span className="quiz-progress__total">/ {props.questionCount}</span>
        </div>
        <div className="heading heading--xs">
          {question.title}
        </div>
      </div>
      <div className="u-margin-top-05">
        {question.options.map(option =>
          (<Option
            key={option.id}
            option={option}
            question={question}
            onSelect={props.onSelect}
          />),
        )}
      </div>
      <button onClick={props.onNextQuestion} disabled={question.score === undefined} className="btn btn--primary u-margin-top">
        {props.isLastQuestion ? 'Uitslag' : 'Volgende vraag'}
      </button>
    </div>
  );
};

export default Question;
