// @flow

import React from 'react';
import Parser from 'react-html-parser';
import type { CardContent } from './types';
import {
  cardPath,
  cardImage,
  cardDescription,
  cardSubtitle,
  cardTitle,
  cardDate,
} from './utils/CardUtils';
import { trackDownload } from './utils/analytics'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import play from '@fortawesome/fontawesome-free-solid/faPlay';

type Props = {
  card: CardContent,
  cardType?: string,
  showDescription?: boolean,
};

const fullImageStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '3px',
  boxShadow: '0 5px 10px rgba(0,0,0,0.1)',
};

const formatTimestamp = (timestamp: ?number): string => {
  if (timestamp != null) {
    const duration = Math.round(timestamp / 1000);
    const minutes = Math.max(Math.floor((duration / 60) % 60), 1);
    return `${minutes}min`
  }
  return '';
};

const Card = ({ card, cardType = 'default', showDescription = false }: Props): any => {
  const cardContent = {
    default: 'card__content',
    DoubleBlock: 'card__content-double-block',
  };

  const cardBody = {
    default: 'card__body',
    DoubleBlock: 'card__body-double-block',
  };

  const description = cardDescription(card);
  const title = cardTitle(card);
  const subtitle = cardSubtitle(card);
  const body =
    description.length > 240
      ? `${description.substring(0, 240).trim()}…`
      : description;
  const largeClass = showDescription ? 'card--large' : '';
  const linkTarget = card.external ? '_blank' : '_self';
  const flexClass = card.linkUrl ? 'card--full-image' : '';
  const cardClass = ['card', 'card--scroll', largeClass, flexClass].join(' ');
  const showBody = card.duration !== undefined || title.length > 0 || body.length > 0;
  const formattedDate = cardDate(card);

  return (
    <a href={cardPath(card)} target={linkTarget} className={cardClass} onClick={() => trackDownload(card)}>
      {card.linkUrl ?
        <div className="ratio ratio--16x9">
          <img alt={card.title} src={cardImage(card)} />
        </div>
      :
        <div className="card__header">
          <div className="scaling-image">
            <div className="scaling-image__inner">
              <img alt={card.title} src={cardImage(card)} />
            </div>
          </div>
          {card.mid && <div className="card__progress-bar"
                            data-behavior="video-progress"
                            data-mid={card.mid} />}
          {card.mid && <div className="card-icon"><FontAwesomeIcon icon={play} className="card-icon--play" /></div>}
        </div>
      }
      {showBody && <div className={cardBody[cardType]}>
        <div className="card-text">
          {card.title && (
            <p className="card-text__title">{title}</p>
          )}
          {subtitle && (
            <p className="card-text__description heading--lato">{subtitle}</p>
          )}
          {card.excerpt && (
            <div className="card__content">{Parser(card.excerpt)}</div>
          )}
          {body && showDescription && (
            <div className={cardContent[cardType]}>{Parser(body)}</div>
          )}
        </div>
        {card.duration && (
          <div className="card__date">
            {card.number && (<span>Afl. {card.number} </span>)}
            {formattedDate}
            <span className="card__time">
              {formatTimestamp(card.duration)}
            </span>
          </div>
        )}
      </div>}
    </a>
  );
};

export default Card;
