// @flow
/* Title and more button */

import React from 'react';

type Props = {
  url: string,
  title: string,
  showMore: boolean,
}

const TitleRow = ({ url, title, showMore }: Props): any => (
  <div>
    {showMore && url && <a className="row__more" href={url}>Meer</a>}
    <a className="row__title" href={url}>{title}</a>
  </div>
);

export default TitleRow;
