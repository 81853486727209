// @flow

import React from 'react';
import type { Row, CardContent } from '../types';
import styles from '../styles/Fragments.module.scss';
import Card from '../Card';

export default class FragmentsContainer extends React.PureComponent<Row<CardContent>> {

  renderVideo = (props: CardContent, index: number): any => (
    <div key={index} className={styles.video}>
      <Card card={props} />
    </div>
    )

  render(): any {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.header}>
          <div className="heading">
            <p>{this.props.title}</p>
          </div>
          <p>{'Meer fragmenten >'}</p>
        </div>
        <div className={styles.videos}>
          {this.props.content.map(this.renderVideo)}
        </div>
      </div>
    );
  }
}
