// @flow

import React from 'react';
import InputField from './InputField';
import styles from './styles/CaptchaField.module.scss';

type Props = {
  captchaImageUrl: string,
  name: string,
  onChange: Function,
  value: string,
};

const CaptchaField = (props: Props): any => (
  <div className={styles.captchaFieldWrapper}>
    <img className={styles.captchaImage} src={props.captchaImageUrl} alt="Captha" />
    <InputField
      labelName="Typ het woord (inclusief hoofdletters) uit de afbeelding over:"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
    />
  </div>
);

export default CaptchaField;
