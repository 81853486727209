// @flow
import React from 'react';
import type { Row, CardContent, Zapper } from '../types';

export const cardImage = (props: CardContent | Zapper): string => {
  if (props.image) {
    return props.image.pomsImageId
      ? `https://images.poms.omroep.nl/image/s800/c800x450/${
          props.image.pomsImageId
        }.jpg`
      : props.image.fallbackImageUrl;
  } else if (props.previewUrl) {
    return props.previewUrl;
  } else if (props.imageUrl) {
    return props.imageUrl;
  }
  return '';
};

export const cardPath = (props: CardContent): string => {
  if (props.link) {
    return props.link;
  } else if (props.mid) {
    return `/mid/${props.mid}`;
  } else if (props.slug) {
    return `/nieuws/${props.slug}`;
  } else if (props.fileUrl) {
    return props.fileUrl;
  } else if (props.linkUrl) {
    return props.linkUrl;
  }
  return '';
};

export const cardDescription = (card: CardContent): string => {
  if (card.description) {
    return card.description;
  }
  return '';
};

export const getDescription = (row: Row<CardContent>): string => {
  if (row.content && row.content[0].excerpt) {
    return row.content[0].excerpt;
  } else if (row.content && row.content[0].description) {
    return row.content[0].description;
  }
  return row.description;
};

export const cardTitle = (card: CardContent): string => {
  if (card.title) {
    return card.title.length > 50
      ? `${card.title.substring(0, 50).trim()}…`
      : card.title;
  }
  return '';
};

export const cardSubtitle = (card: CardContent): string => {
  if (card.subtitle) {
    return card.subtitle.length > 50
    ? `${card.subtitle.substring(0, 50).trim()}...`
    : card.subtitle;
  }
  return '';
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString('nl', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const formatDateShort = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString('nl', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const formatDateLong = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString('nl', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  }) + " om " + date.toLocaleTimeString('nl', {
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const cardDate = (card: CardContent): string => {
  if (!card.startsAt) { return "" }
  return card.longDate ? formatDateLong(card.startsAt) : formatDate(card.startsAt);
}
