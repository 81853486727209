// @flow

import React from 'react';
import type { Row } from '../types';
import type { Podcast as PodcastType } from '../../Podcast/types';
import Podcast from '../../Podcast';

export default (row: Row<PodcastType>) => {
  if (row.content.length < 1) { return null; }
  const podcast = row.content[0];
  return (
    <div className="row">
      <div className="row__block new-design padding-4">
        <Podcast {...podcast} />
      </div>
    </div>
  );
};
