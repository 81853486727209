// @flow

import React from 'react';
import type { Row, ImageLinkContent } from './types';
import { cardPath, cardImage } from './utils/CardUtils';
import styles from './styles/ImageLink.module.scss';

const ImageLink = (row: Row<ImageLinkContent>): any => {
  if (row.content.length < 1) { return null; }
  const image = row.content[0];
  const target = image.external ? '_blank' : '_self';
  return (
    <div className={styles.wrapperBlock}>
      <a rel="noopener noreferrer" target={target} href={cardPath(image)}>
        <img className={styles.fullImage} alt={cardPath(image)} src={cardImage(image)} />
      </a>
    </div>
  );
};

export default ImageLink;
