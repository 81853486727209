// @flow

import React from 'react';

const truncateTitle = (title: string): string => {
  if (title.length > 50) {
    return `${title.substring(0, 50).trim()}…`;
  }
  return title;
};

const Title = (props: {title: string, link?: ?string}): React$Element<'a'> | React$Element<'p'> => {
  const titleElement = <p className="article__title">{props.title}</p>;
  const title = truncateTitle(props.title);
  if (props.link) {
    return (<a href={props.link} style={{display: "inline-block"}}>
              { titleElement }
            </a>);
  } else {
    return titleElement;
  }
};
export default Title;
