// @flow

import React from 'react';
import SingleRow from './SingleRow';
import Card from '../Card';
import TitleRow from '../TitleRow';
import { showTitle } from '../utils/row';
import type { CardContent, Row } from '../types';

export default class Highlight extends React.PureComponent<Row<CardContent>> {
  renderCard = (props: CardContent, index: number): any => (
    <div key={index} className="row-col__cell">
      <Card card={props} />
    </div>
  );

  render(): any {
    if (this.props.content.length < 5) { return <SingleRow {...this.props} />; }
    const firstCard = this.props.content[0];
    const remainingCards = this.props.content.slice(1, 5);
    const showMore = this.props.content.length > 5;
    return (
      <div className="row-col">
        {showTitle(this.props) && <TitleRow {...this.props} showMore={showMore} />}
        <div className="row-col__columns">
          <div className="row-col__column">
            <div className="row-col__cell">
              {firstCard && <Card card={firstCard} showDescription={true} />}
            </div>
          </div>
          <div className="row-col__column">
            <div className="row-col__grid">
              {remainingCards.map(this.renderCard)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
