// @flow
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { camelizeKeys } from 'humps';
import $ from 'jquery';
import padStart from 'string.prototype.padstart';
import Subsite from '../components/Subsite/index';
import Card from '../components/Subsite/Card';

if (!String.prototype.padStart) { padStart.shim(); }

$(() => {
  const el = $('#subsite');
  const props = el.data('props');
  ReactDOM.render(React.createElement(Subsite, camelizeKeys(props)), el[0]);

  const card = $('#card');
  if (card.length > 0) {
    const cardProps = card.data('props');
    ReactDOM.render(React.createElement(Card, camelizeKeys(cardProps)), card[0]);
  }
});
