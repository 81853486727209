// @flow

import React from 'react';
import Quiz from '../../Quiz';
import type { Row } from '../types';
import type { QuizContent } from '../../Quiz/types';

const QuizBlock = (row: Row<QuizContent>) => {
  if (row.content.length < 1) { return null; }
  const quiz = row.content[0];
  return (
    <div className="row">
      <div className="row__block new-design">
        <Quiz {...quiz} />
      </div>
    </div>
  );
};

export default QuizBlock;
