// @flow

import React from 'react';
import Parser, { convertNodeToElement } from 'react-html-parser';
import { camelize } from "humps";
import type { Row, InstagramEmbedContent } from '../types';

type Props = Row<InstagramEmbedContent>;
type State = {
  loading: boolean,
  embedHtml: ?string;
}

class InstagramEmbed extends React.PureComponent<Props, State> {
  constructor(props: Props): void {
    super(props);
    this.state = {
      loading: true,
      embedHtml: null
    }
  }

  fetchInstagramEmbed = (): any => {
    const accessToken = window.facebookAccessToken;
    return fetch(`https://graph.facebook.com/v12.0/oembed_post?url=${this.props.content[0].embedUrl}&access_token=${accessToken}`)
      .then(response => {
        if (response.ok) { return response.json() }
    })
  }

  loadInstagram = (): void => {
    if (window.ccm.getCategoryConsent('social') == true) {
      this.fetchInstagramEmbed().then(res => {
        this.setState(
          { embedHtml: res.html, loading: false }
          , () => { 
            if (window.instgrm) {
              window.instgrm.Embeds.process();
            }
          }
        );
      });
    } else if (window.ccm.getCategoryConsent('social') == false) {
      this.setState({ loading: false });
    }
  }

  componentDidMount(): void {
    if (window.ccm) {
      this.loadInstagram();
    } else {
      window.addEventListener('CCM_ready', () => {
        this.loadInstagram();
      }, false);
    }
  }

  render(): React$Element<any> {
    return (
      <React.Fragment>
        {this.state.loading ? (<div />) :
          this.state.embedHtml ?
            (<div className="row instagram__row">
              <div className="row__block">
                <div dangerouslySetInnerHTML={{ __html: this.state.embedHtml }} />
              </div>
            </div>) :
            (<div className="row instagram__row">
              <div className="row__block u-centered u-padding">
                <p className="u-padding">Hier staat een Instagram foto. We tonen deze niet omdat je sociale cookies niet geaccepteerd hebt.</p>
                <p>
                  <a className="btn btn--primary" href={this.props.content[0].instagramUrl} target="_blank">Bekijk de foto op Instagram.</a>
                  &nbsp;
                  <a className="btn btn--grey" href="#" onClick={window.ccm.showCookieMessage}>Of wijzig je cookie instellingen.</a>
                </p>
              </div>
            </div>)
        }
      </React.Fragment>
    );
  };
}

export default InstagramEmbed;
