// @flow

import React from 'react';
import Card from '../Card';
import CTA from '../Cta';
import TitleRow from '../TitleRow';
import { showTitle } from '../utils/row';
import type { CardContent, Row } from '../types';

const DoubleBlock = (props: Row<CardContent>): any => {
  if (props.content.length < 2) {
    return <CTA {...props} />;
  }
  const cards = props.content.slice(0, 2);
  const showMore = props.content.length > 2;
  return (
    <div className="row-col">
      {showTitle(props) && <TitleRow {...props} showMore={showMore} />}
      <div className="double-block">
        {cards.map((card, index) => (
          <div key={index} className="double-block__block">
            <Card card={card} showDescription={true} type="DoubleBlock" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DoubleBlock;
