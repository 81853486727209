// @flow

import React from 'react';
import type { Row, CardContent } from '../types';
import Card from '../Card';
import TitleRow from '../TitleRow';
import SingleRow from './SingleRow';
import { showTitle } from '../utils/row';

const DoubleRow = (props: Row<CardContent>): any => {
  const topCards = props.content.slice(0, 4);
  const bottomCards = props.content.slice(4, 8);
  if (bottomCards.length < 4) { return <SingleRow {...props} />; }
  const showMore = props.content.length > 8;

  return (
    <div className="row-col">
      {showTitle(props) && <TitleRow {...props} showMore={showMore} />}
      <div className="row-col__rows">
        <div>
          <div className="row-col__column">
            {topCards.map((card, index) =>
              <div key={index} className="row-col__cell"><Card card={card} /></div>,
            )}
          </div>
        </div>
        <div>
          <div className="row-col__column">
            {bottomCards.map((card, index) =>
              <div key={index} className="row-col__cell"><Card card={card} /></div>,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubleRow;
