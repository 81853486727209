// @flow

import $ from 'jquery';
import * as React from 'react';
import _ from 'lodash';
import SanitizedHTML from 'react-sanitized-html';
import Parser from 'react-html-parser';
import InputField from '../InputField';
import CaptchaField from '../CaptchaField';
import styles from '../styles/ContactForm.module.scss';
import type { Row, ContactFormContent } from '../types';

const PRETTY_FIELD_NAMES = {
  first_name: 'Voornaam',
  last_name: 'Achternaam',
  email: 'Email adres',
  parent_email: 'E-mailadres ouder/verzorger',
  captcha_solution: 'Captcha woord',
  message: 'Bericht',
  upload: 'Upload',
};

const QUESTIONS_PER_PAGE = 4;

type State = {
  step: number,
  formSent: boolean,
  first_name: string,
  last_name: string,
  email: string,
  parent_email: string,
  message: string,
  errors: Object,
  captcha_solution: string,
  upload: string,
  field_values: Array<string>,
  policies_accepted: string,
};

export default class ContactForm extends React.Component<Row<ContactFormContent>, State> {
  constructor(props: Row<ContactFormContent>): void {
    super(props);
    this.state = this.getInitialState();
  }

  pagesAmount = (): number =>
    Math.max(Math.ceil(this.props.content[0].valueFields.length / QUESTIONS_PER_PAGE) + 1, 2);

  getInitialState = () => {
    const intialState = {
      step: 1,
      formSent: false,
      first_name: '',
      last_name: '',
      errors: Object,
      email: '',
      parent_email: '',
      message: '',
      upload: '',
      captcha_solution: '',
      policies_accepted: '0',
      field_values: this.props.content.length < 1 ? [] : this.props.content[0].valueFields.map(() => ''),
    };

    return intialState;
  };

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value });
  };

  handleCheckbox = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.checked ? '1' : '0',
    });
  };

  handleFieldChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const index = event.currentTarget.name;
    const newValue = event.currentTarget.value;
    this.setState(prevState => ({
      ...prevState,
      field_values: prevState.field_values.map((v, i) => {
        if (parseInt(index, 10) === i) {
          return newValue;
        }
        return v;
      }),
    }));
  };

  clearForm = () => {
    this.setState(this.getInitialState());
  };

  handleUpload = (event: Object) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState(prevState => ({
        ...prevState,
        upload: e.target.result,
      }));
    };
    reader.readAsDataURL(file);
  }

  submitForm = (event: SyntheticEvent<>) => {
    event.preventDefault();
    const pages = this.pagesAmount()
    const { errors, formSent, step, ...rest } = this.state;
    const formData = {
      ...rest,
      encrypted_captcha_problem: this.props.content[0].encryptedCaptchaProblem,
    };
    const url = this.props.content[0].url;
    const slug = this.props.content[0].serieSlug;
    $.ajax({
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'POST',
      url: url,
      data: JSON.stringify({
        contact: { ...formData },
        contact_form_id: this.props.content[0].id,
      }),
      contentType: 'application/json',
    })
      .done(() => {
        this.setState(prevState => ({
          ...prevState,
          errors: {},
          formSent: true,
        }));
      })
      .fail((response) => {
        this.setState(prevState => ({
          ...prevState,
          errors: response.responseJSON,
        }));
        const errElement = document.getElementById('errors');
        if (errElement) {
          errElement.scrollIntoView();
        }
      });
  };

  renderUploadInput = () => (
    <div className={`u-margin-top ${styles.upload}`}>
      <label htmlFor="upload">
        {this.props.content[0].uploadNote}
      </label>
      <input
        type="file"
        id="upload"
        name="upload"
        accept="image/png, image/jpeg"
        onChange={this.handleUpload}
        className={styles.uploadField}
      />
      <span className="text-grey-600">Let op: dit bestand mag maximaal 5MB groot zijn.</span>
    </div>
  );

  renderQuestions = (start: number, amount: number): Array<React.Element<'div'>> =>
    this.props.content[0].valueFields.slice(start, start + amount).map((field: any, index: number) => {
      const questionIndex = start + index;
      return (
        <div key={questionIndex} className="u-margin-top-05">
          <InputField
            name={questionIndex.toString()}
            labelName={field.name}
            placeholder="Antwoord"
            onChange={this.handleFieldChange}
            value={this.state.field_values[questionIndex]}
          />
        </div>
      );
    });

  privacyLink = (
    <a
      href="/nieuws/gebruiksvoorwaarden"
      target="_blank"
      rel="noopener noreferrer"
      className="link-underlined"
    >
      privacyregels
    </a>
  );

  formatErrors = (key: string, value: string) => {
    const result = [];
    if (key === 'base') {
      result.push(value[0]);
    } else {
      for (let i = 0, len = value.length; i < len; i += 1) {
        const error = value[i];
        const returnKey = key in PRETTY_FIELD_NAMES ? PRETTY_FIELD_NAMES[key] : key;
        result.push(`${returnKey} ${error}`);
      }
    }
    return result;
  };

  progressDot = (pos: number) => (
    <div key={pos} className={pos === this.state.step ? styles.dot_purple : styles.dot_grey} />
  );
  renderProgressDots = () => {
    const pages = this.pagesAmount()
    return (<h1 className="heading u-centered">
      {_.times(pages, i => this.progressDot(i + 1))}
    </h1>)
  };

  nextPage = () => {
    this.setState(prevState => ({
      ...prevState,
      step: prevState.step + 1,
    }));
  };
  prevPage = () => {
    this.setState(prevState => ({
      ...prevState,
      step: prevState.step - 1,
    }));
  };

  renderButtons = () => {
    const step = this.state.step;
    const pages = this.pagesAmount()
    return (
      <div className={styles.buttonWrapper}>
        {step < pages && (
          <button className={`btn btn--grey ${styles.nextButton}`} onClick={this.nextPage}>
            {'Volgende >'}
          </button>
        )}
        {step > 1 && (
          <button className={`btn btn--grey ${styles.prevButton}`} onClick={this.prevPage}>
            {'< Vorige'}
          </button>
        )}
        {step === pages && (
          <button
            className={`btn btn--primary ${styles.sendButton}`}
            type="button"
            onClick={this.submitForm}
          >
            Versturen
          </button>
        )}
      </div>
    );
  };

  questionPageNum = () => {
    const step = this.state.step;
    const pages = this.pagesAmount()
    if (step > 1 && step < pages) {
      return step;
    }
    return undefined;
  };

  renderForm = () => {
    const questionPage = this.questionPageNum();
    const form = this.props.content[0];
    const step = this.state.step;
    const pages = this.pagesAmount()
    switch (step) {
      case 1:
        return (
          <div id="step1">
            <div className="l-row">
              <div className="l-col-6">
                <InputField
                  name="first_name"
                  labelName={PRETTY_FIELD_NAMES.first_name}
                  value={this.state.first_name}
                  onChange={this.handleChange}
                />
              </div>
              <div className="l-col-6">
                <InputField
                  name="last_name"
                  labelName={PRETTY_FIELD_NAMES.last_name}
                  onChange={this.handleChange}
                  value={this.state.last_name}
                />
              </div>
            </div>
            {form.showMessageField && (
              <div className="u-margin-top-05">
                <label htmlFor="message">Bericht of vraag
                <textarea
                  rows="4"
                  name="message"
                  className={`${styles.input_text} ${styles.input_text__area}`}
                  placeholder={PRETTY_FIELD_NAMES.message}
                  onChange={this.handleChange}
                  value={this.state.message}
                /></label>
              </div>
            )}

            {this.renderQuestions(0, QUESTIONS_PER_PAGE)}
          </div>
        );

      case questionPage:
        return (
          <div>{this.renderQuestions(QUESTIONS_PER_PAGE * (step - 1), QUESTIONS_PER_PAGE)}</div>
        );

      case pages:
        return (
          <div id="stepLast">
            <div>
              <InputField
                name="parent_email"
                labelName={PRETTY_FIELD_NAMES.parent_email}
                onChange={this.handleChange}
                value={this.state.parent_email}
              />
            </div>
            {form.withUpload && this.renderUploadInput()}
            {!!form.captchaEnabled && (
              <CaptchaField
                value={this.state.captcha_solution}
                onChange={this.handleChange}
                name="captcha_solution"
                captchaImageUrl={form.captchaImageUrl}
              />
            )}
            <div className="u-margin-top">
              <label htmlFor="policies_accepted">
                <input
                  id="policies_accepted"
                  type="checkbox"
                  name="policies_accepted"
                  value={this.state.policies_accepted}
                  onChange={this.handleCheckbox}
                />
                Ik ga akkoord met de {this.privacyLink} van NPO Zapp.
              </label>
            </div>

            <div className="message u-margin-top">
              We gebruiken je gegevens {form.dataUseGoal}. Daarna verwijderen we deze gegevens
              weer.
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  render(): any {
    if (this.props.content.length < 1) { return null; }
    const form = this.props.content[0];
    const formSent = this.state.formSent;
    const errors = [].concat(
      ...Object.keys(this.state.errors).map(err => this.formatErrors(err, this.state.errors[err])),
    );

    return (
      <div className={styles.container}>
        <div className={styles.inner}>
          <h2 className="heading heading--s u-centered">{form.name}</h2>
          {!!form.imageUrl && (
            <div className={styles.form_image_wrapper}>
              <img className={styles.form_image} src={form.imageUrl} alt={form.name} />
            </div>
          )}
          {!!form.description && <div className="u-margin-top">{Parser(form.description)}</div>}
          {this.renderProgressDots()}
          {errors.length > 0 &&
            !formSent && (
              <div id="errors" className="u-margin-top flash flash--warning">
                {errors.map((err, index) => <h1 key={index}>{err}</h1>)}
              </div>
          )}
          <div id="contactForm" className={styles.contactForm}>
            {formSent ? (<div id="sentMessage">
              {form.confirmMessage ? <div className="u-margin-top u-centered"><SanitizedHTML html={form.confirmMessage} /></div> : 
                <h1 className="u-margin-top heading u-centered">
                    Bedankt voor het invullen
                </h1>
              }
            </div>
            ) : (
              <div>
                <div className="u-margin-top">
                  Wil je meedoen? Vraag je ouders/verzorgers om onderstaand formulier voor jou in te
                  vullen.
                </div>
                <hr />
                <div className="u-margin-top">{this.renderForm()}</div>
                <div className={styles.bottom_buttons}>{this.renderButtons()}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
