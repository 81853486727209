// @flow

import React from 'react';
import Parser from 'react-html-parser';
import type { Row, CardContent } from 'components/Subsite/types';


const Description = (props: {description: string}): React$Element<'div'> => {
  const description = Parser(props.description);
  return (
    <div className="article__content">{description}</div>
  );
};

export default Description;
