// @flow

import React from 'react';
import Result from './Result';
import Question from './Question';
import type { Question as QuestionType } from './types';

type Props = {
  onNextQuestion: Function,
  onRestart: Function,
  onSelect: Function,
  title: string,
  questionIndex: number,
  questions: QuestionType[],
  imageUrl: string,
  description: string,
  results: any[],
};

const Quiz = (props: Props) => {
  const { questions, questionIndex, imageUrl } = props;
  const question = questions[questionIndex];

  return (
    <div className="quiz-container">
      {imageUrl && <img style={{ width: '100%' }} src={imageUrl} alt={props.title} />}
      <h2 className="heading heading--s u-margin-top">{props.title}</h2>
      <p className="u-margin-top">{props.description}</p>
      {questionIndex === questions.length
        ?
          <Result
            results={props.results}
            questions={questions}
            onRestart={props.onRestart}
          />
          :
          <Question
            questionIndex={questionIndex}
            questionCount={questions.length}
            key={question.id}
            question={question}
            onSelect={props.onSelect}
            onNextQuestion={props.onNextQuestion}
            isFirstQuestion={questionIndex === 0}
            isLastQuestion={questionIndex + 1 === questions.length}
          />
        }
    </div>
  );
};

export default Quiz;
