// @flow

import React from 'react';
import Title from './Title';
import Description from './Description';
import Button from './Button';
import type { Row, CardContent, Zapper } from '../types';
import { cardImage, getDescription } from '../utils/CardUtils';

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString('nl', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' });
};

const getDate = (content: CardContent | Zapper): ?string => {
  if (content.startsAt) {
    return content.startsAt;
  }
  if (content.onlineAt) {
    return content.onlineAt;
  }
  return null;
};

const CTA = (props: Row<CardContent> | Zapper): any => {
  const content = props.content ? props.content[0] : props;
  if (!content) return null;

  const link = content.link ? content.link : null;
  const imageElement = <img alt={content.title} style={{ width: '100%' }} src={cardImage(content)} />;
  const description = props.content ? getDescription(props) : props.description;
  const onlineAt = getDate(content);
  return (
    <div className="row">
      <div className="row__block">
        <div className="article">
          <div className="article__image-column">
            {content.link ? <a href={content.link} >{imageElement}</a> : imageElement}
          </div>
          <div className="article__text-column">
            <div className="article__body">
              <p className="article__tag">{props.title}</p>
              {onlineAt && !props.hideDate && <p className="article__date">{formatDate(onlineAt)}</p>}
              {content.title && <Title title={content.title} link={link} />}
              <Description description={description} />
            </div>
            {props.content && <Button card={props.content[0]} buttonText={props.buttonText} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
