// @flow

import React from 'react'
import type { CardContent } from '../types'
import { trackDownload } from '../utils/analytics'

type Props = {
  card: CardContent,
  buttonText: string
}

type ButtonProps = {
  href: string,
  label: string,
  target?: string,
  onClick?: Function
}

const Button = ({href, label, target, onClick}: ButtonProps) => {
  return (
    <a href={href} target={target} onClick={onClick} className="btn btn--primary article__button">
      {label}
    </a>
  )
}

export default ({card, buttonText}: Props) => {
  if (card.slug) { // Card is an article
    return <Button 
      href={card.link}
      label={buttonText || 'Lees verder'}
    />
  }
  if (card.fileUrl) { // Card is a download
    return <Button
      href={card.fileUrl}
      label={buttonText || 'Download'}
      onClick={() => trackDownload(card)}
    />
  }
  if (card.mid) { // Card is a video, potentially with link
    return <Button
      href={card.link || `/mid/${card.mid}`}
      label={buttonText || 'Kijk nu'}
    />
  }
  if (card.link) { // Card is a contest or awards event
    return <Button
      href={card.link}
      label={buttonText || 'Doe mee'}
    />
  }
  if (card.url) { // Card is an external link
    return <Button 
      href={card.url}
      target={card.newTab ? '_blank' : undefined}
      label={buttonText || 'Lees verder'}
    />
  }
  return null;
}
