// @flow

import React from 'react';
import Card from '../Card';
import DoubleBlock from './DoubleBlock';
import TitleRow from '../TitleRow';
import { showTitle } from '../utils/row';
import type { Row, CardContent } from '../types';

const SingleRow = (props: Row<CardContent>): any => {
  if (props.content.length < 4) {
    return <DoubleBlock {...props} />;
  }
  const cards = props.content.slice(0, 4);
  const showMore = props.content.length > 4;
  return (
    <div className="row-col">
      {showTitle(props) && <TitleRow {...props} showMore={showMore} />}
      <div className="row-col__columns">
        {cards.map((card, index) => (
          <div key={index} className="row-col__cell">
            <Card card={card} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SingleRow;
