// @flow

import React from 'react';
import Player from '../../Player';
import leftArrow from '../images/pijl-links-white.png';
import rightArrow from '../images/pijl-rechts-white.png';
import type { Row, PhotoGalleryContent, PhotoType } from '../types';
import { fetchPlayer, addPlayer } from '../../Player/utils';
import { filter, map, uniq } from 'lodash';

const Photo = (photo: PhotoType) => {
  const photoEl = <div style={{ backgroundImage: `url("${photo.imageUrl}")` }} className="photo-album__image" />;
  if (photo.linkUrl !== undefined) {
    return <a href={photo.linkUrl}>{photoEl}</a>;
  }
  return photoEl;
};

const Video = (video: PhotoType) => (
  <div className="photo-album__video">
    <div style={{ backgroundImage: `url(${video.imageUrl})` }} className="photo-album__video-bg" />
    <div className="photo-album__player">
      {video.mid !== undefined && <div className="photo-album__player-inner">
        <Player
          mid={video.mid}
          playerTokenPayload={video.playerTokenPayload}
          lazy={true}
          fetch={fetchPlayer}
          render={addPlayer}
          image={video.imageUrl}
        />
      </div>}
    </div>
  </div>
);

type State = {
  currentPhotoIndex: number,
  currentDate: string
}

export default class PhotoGallery extends React.PureComponent<Row<PhotoGalleryContent>, State> {
  memoizedPhotos: Array<PhotoType> | null;

  constructor(props: any): void {
    super(props);
    this.state = { currentPhotoIndex: 0, currentDate: this.props.content[0] && this.props.content[0].photos.slice(-1)[0].uploadedOn}
  }

  photos = (): Array<PhotoType> => {
    if(!this.memoizedPhotos){
      if (this.props.content[0] != null) {
        const photos = this.props.content[0].photos;
        if(this.props.content[0].filterableByDate){
          this.memoizedPhotos = filter(photos, {uploadedOn: this.state.currentDate})
        } else {
          this.memoizedPhotos = photos
        }
      } else {
        this.memoizedPhotos = [];
      }
    }
    return this.memoizedPhotos
  }

  nextPic = (): void => {
    if (this.state.currentPhotoIndex === this.photos().length - 1) {
      this.setState({ currentPhotoIndex: 0 });
    } else {
      this.setState(prevState => ({
        currentPhotoIndex: prevState.currentPhotoIndex + 1,
      }));
    }
  }

  prevPic = (): void => {
    if (this.state.currentPhotoIndex === 0) {
      this.setState({ currentPhotoIndex: this.photos().length - 1 });
    } else {
      this.setState(prevState => ({
        currentPhotoIndex: prevState.currentPhotoIndex - 1,
      }));
    }
  }

  pictureStyle = (photoIndex: number, arrayIndex: number): Object => (
    { display: photoIndex === arrayIndex ? 'block' : 'none' }
  )

  setDate = (date: string): void => {
    this.setState({currentDate: date, currentPhotoIndex: 0});
    this.memoizedPhotos = null
  }

  render(): any {
    const currIndex = this.state.currentPhotoIndex;
    const currentPic = this.photos()[currIndex];
    const galleryLen = this.photos().length;
    if (galleryLen < 1) { return null; }
    const dates = uniq(map(this.props.content[0].photos, 'uploadedOn'));
    return (
      <div className="l-content">
        <div className="photo-album">
          {this.photos().map((photo, index) => (
            <div key={index} style={this.pictureStyle(currIndex, index)}>
              {(currentPic.mid === undefined || currentPic.mid === '') ? <Photo {...photo} /> : <Video {...photo} />}
            </div>
          ))}
          <div className="photo-album__controls-wrapper">
            <div className="photo-album__controls" >
              <div className="photo-album__titles">
                <p className="photo-album__title">{this.props.title}</p>
                <p className="photo-album__name">{currentPic.title}</p>
              </div>
              <div className="photo-album__paginator">
                <p className="photo-album__counter">
                  { this.props.content[0].filterableByDate &&
                    <select className='photo-album__select' value={this.state.currentDate} onChange={e => this.setDate(e.currentTarget.value)}>
                      {dates.map((date)=>
                        <option key={date}>{date}</option>
                        )
                      }
                    </select>
                  }
                  {`${currIndex + 1}/${galleryLen}`}
                </p>
                <div className="photo-album__button-group">
                  <img alt="Left" className="photo-album__button" src={leftArrow} onClick={this.prevPic} />
                  <img alt="Right" className="photo-album__button" src={rightArrow} onClick={this.nextPic} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
