// @flow

import React from 'react';
import Parser from 'react-html-parser';
import type { Row, TextBlockContent } from '../types';

const TextBlock = (props: Row<TextBlockContent>): any => {
  const content = props.content[0];
  if (!content) { return null; }
  return (
    <div className="row">
      <div className="row__block">
        <div className="text-block__body">{Parser(content.text)}</div>
      </div>
    </div>
  );
};

export default TextBlock;
