// @flow

import React from 'react';

type Props = {
  coverImage: ?string,
  customHeaderText: ?string,
  customHeaderMid: ?string,
  title: ?string,
  namespace: ?string
}

const Header = ({coverImage, customHeaderText, customHeaderMid, title, namespace}: Props): any => (
  <div className="cover__subsiteadmin">
    <div className="cover__background">
      {renderCoverImage(coverImage)}
    </div>
    <div className="cover__gradient-bottom" />
    <div className="cover__subsitepage-text">
      <h1 className="cover__subsitepage-title">{title || "No text to be found"}</h1>
    </div>
  </div>
);

const renderCoverImage = (coverImage) => (
  <React.Fragment>
    {coverImage ? <div className="cover__background-image" style={{backgroundImage: `url(${coverImage})`}}/> :
    <div className="cover__empty" />}
  </React.Fragment>
);

export default Header;
