// @flow

import React, { useState, useRef, useEffect } from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import play from '@fortawesome/fontawesome-free-solid/faPlay';
import pause from '@fortawesome/fontawesome-free-solid/faPause';

import { formatDateShort } from '../Subsite/utils/CardUtils';
import type { Podcast } from './types';

export const formatDuration = (duration: string) => {
  const elements = duration.split(":").map((element) => parseInt(element));
  const hours = elements[0];
  var minutes = elements[1];
  if (elements[2] > 30) {
    minutes++;
  }
  const hourString = hours > 0 ? `${hours} u ` : '';
  return `${hourString}${minutes} min`;
}

const Episode = ({activeFileUrl, playAudio, fileUrl, title, duration, publishedAt, description}) => {
  const audio = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    if (activeFileUrl !== fileUrl) {
      audio.current && audio.current.pause();
    }
  }, [activeFileUrl])

  useEffect(() => {
    if (audio.current) {
      audio.current.addEventListener("play", () => setPlaying(true));
      audio.current && audio.current.addEventListener("pause", () => setPlaying(false));
    }
  }, [audio])

  return (
    <div className="padding-bottom-2">
      <div className="flex">
        <div onClick={(e) => { playAudio(fileUrl, audio.current); }} className="podcast__play">
          {playing ? <FontAwesomeIcon icon={pause} /> : <FontAwesomeIcon icon={play} />}
        </div>
        <div className="flex-1">
          <p className="podcast__episode-title">{title}</p>
          <p>
            <span style={{float: "right"}}>
              <a href="#" className="font-sm text-grey-300" onClick={(e) => { e.preventDefault(); setShowDescription(!showDescription); }}>Details</a>
            </span>
            <span className="text-grey-300 font-sm">
              {formatDateShort(publishedAt)} &mdash; {formatDuration(duration)}
            </span>          
          </p>
        </div>
      </div>
      <div className={showDescription ? undefined : "hidden"}>
        <p className="padding-top-1">{description}</p>
      </div>
      <div className={activeFileUrl !== fileUrl ? "hidden" : undefined}><audio className="padding-top-1" style={{width: "100%"}} ref={audio} controls src={fileUrl} preload="none" /></div>
    </div>
  )
}

export default ({title, description, imageUrl, episodes}: Podcast) => {
  const [activeFileUrl, setActiveFileUrl] = useState(null);

  const playAudio = (fileUrl, player) => {
    setActiveFileUrl(fileUrl);
    if (player) {
      if (player.paused) {
        player.play();
      } else {
        player.pause();
      }
    }
  }

  return (
    <div className="podcast">
      <div className="podcast__header">
        <div className="podcast__image"><img src={imageUrl} alt={title} width="100%" /></div>
        <p className="podcast__title">{title}</p>
        <p className="podcast__description">{description}</p>
      </div>
      
      <div className="podcast__episodes">
        {episodes.map((episode) => (
          <Episode key={episode.fileUrl} playAudio={playAudio} activeFileUrl={activeFileUrl} {...episode} />
        ))}
      </div>
    </div>
  );
}
