// @flow

import $ from 'jquery';
import React from 'react';
import { camelizeKeys } from 'humps';
import Player from '../../Player';
import type { Row, PollContent } from '../types';
import radioOn from '../images/radio-on.png';
import radioOff from '../images/radio-off.png';

const maxScore = poll => Math.max(...poll.pollOptions.map(o => o.percentage));
const errorMessage = "Oeps, hier ging iets mis. Open de pagina nog een keer en probeer het opnieuw!"

const ProgressBar = ({ winner, percentage }): any => {
  const winnerClass = winner ? 'progress-bar--winner' : '';
  return (
    <div className={['progress-bar', winnerClass].join(' ')}>
      <div className="progress-bar__inner" style={{ width: `${percentage}%` }} />
      <div className="progress-bar__number">{`${percentage}%`}</div>
    </div>
  );
};

const Option = ({ voted, winner, selectOption, option, selected }): any => {
  if (voted) {
    return (
      <div className="poll-option--result">
        <p className="poll-option__text">{option.title}</p>
        <div><ProgressBar winner={winner} percentage={option.percentage} /></div>
      </div>
    );
  }
  const img = selected ? radioOn : radioOff;
  return (
    <div className="poll-option" onClick={() => selectOption(option.id)}>
      <div className="poll-option__img"><img alt="" src={img} /></div>
      <p className="poll-option__text">{option.title}</p>
    </div>
  );
};

type TextPollState = {
  selectedOption: string,
}

type TextPollProps = {
  poll: PollContent,
  vote: Function,
  showError: boolean
}

class TextPoll extends React.Component<TextPollProps, TextPollState> {
  constructor(props: TextPollProps): void {
    super(props);
    this.state = { selectedOption: '', showError: false };
  }

  selectOption = (optionId: string): void => {
    this.setState({ selectedOption: optionId });
  }

  render(): any {
    const poll = this.props.poll;
    const buttonDisabled = this.state.selectedOption === '';
    const winnerScore = maxScore(poll);
    const vote = () => this.props.vote(this.state.selectedOption);
    return (
      <div className="row-container__inner">
        <div className="row-container__column">
          <img src={poll.imageUrl} alt={poll.title} className="poll__image" />
        </div>
        <div className="row-container__column">
          <div className="poll__body">
            <div className="poll__inner">
              <div className="poll__header">
                <p className="poll__tag">Poll</p>
                {this.props.showError && <div className="poll__error">{errorMessage}</div>}
                <p className="poll__title">{poll.title}</p>
              </div>
              {poll.pollOptions.map(option => (
                <Option
                  selected={option.id === this.state.selectedOption}
                  selectOption={this.selectOption}
                  key={option.id}
                  option={option}
                  voted={poll.voted}
                  winner={option.percentage === winnerScore}
                />))}
              {!poll.voted && <button className="poll__button" onClick={vote} disabled={buttonDisabled}>Stemmen</button>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ImageOption = ({ selectOption, option, voted, winner }): any => (
  <div className="poll-image-option">
    {option.mid
    ? <div className="ratio ratio--16x9"><Player playerTokenPayload={option.playerTokenPayload} mid={option.mid} /></div>
    : <img className="poll-image-option__img" alt="" src={option.imageUrl} />}
    <div className="poll-image-option__body">
      <p className="poll-image-option__text">{option.title}</p>
      {voted
        ? <ProgressBar percentage={option.percentage} winner={winner} />
        : <button className="poll__button" onClick={selectOption}>Stemmen</button>}
    </div>
  </div>
);

type ImagePollProps = {
  poll: PollContent,
  vote: Function,
  showError: boolean
}

const ImagePoll = (props: ImagePollProps) => {
  const poll = props.poll;
  const winnerScore = maxScore(poll);
  return (
    <div className="row-container__inner poll--image">
      <div className="poll__body">
        <div className="poll__header">
          <p className="poll__tag">Poll</p>
          {props.showError && <div className="poll__error">{errorMessage}</div>}
          <p className="poll__title">{poll.title}</p>
        </div>
        <div className="poll__image-collection">
          {poll.pollOptions.map(option => (
            <ImageOption
              selectOption={() => props.vote(option.id)}
              key={option.id}
              option={option}
              voted={poll.voted}
              winner={option.percentage === winnerScore}
            />))}
        </div>
      </div>
    </div>
  );
};

type State = {
  poll: PollContent,
  showError: boolean,
};

export default class Poll extends React.Component<Row<PollContent>, State> {
  constructor(props: Row<PollContent>): void {
    super(props);
    this.state = { poll: props.content[0], showError: false };
  }

  vote = (optionId: string) => {
    const poll = this.props.content[0];
    const url = `/peilingen/${poll.id}/stemmen/`;
    $.ajax({
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      url,
      method: 'POST',
      headers: { 'x-new-poll': 'true' },
      dataType: 'json',
      data: { stem: { votable_id: optionId, votable_type: 'PollOption' } },
      success: (res) => { this.setState({ poll: camelizeKeys(res) }); },
      error: () => { this.setState({ showError: true }); }
    });
  }

  render(): any {
    if (this.props.content.length < 1) { return null; }
    const poll = this.state.poll;
    return (
      <div className="row-container">
        {poll.pollType === 'text'
          ? <TextPoll poll={poll} vote={this.vote} showError={this.state.showError} />
          : <ImagePoll poll={poll} vote={this.vote} showError={this.state.showError} />}
      </div>
    );
  }
}
