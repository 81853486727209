// @flow

import * as React from 'react';

type Props = {
  ageRating: string,
  contentRatings: Array<string>
}

const CONTENT_RATING_DESCRIPTIONS = {
  ANGST: 'Angst',
  DISCRIMINATIE: 'Discriminatie',
  DRUGS_EN_ALCOHOL: 'Drugs- en/of alcoholgebruik',
  GEWELD: 'Geweld',
  GROF_TAALGEBRUIK: 'Grof taalgebruik',
  SEKS: 'Seks'
};

class AgeRatings extends React.Component<Props> {
  ageRatingIcon = (ageRating: string): ?React.Element<'img'> => {
    if (!ageRating) return null;
    const title = ageRating === 'ALL' ?
                              'Alle leeftijden' :
                              `Mogelijk schadelijk tot ${ageRating} jaar`;
    return (<img
      alt={title}
      src={`/assets/ratings/${ageRating.toLowerCase()}.png`}
      title={title} className="rating-icon"
    />);
  };

  contentRatingIcons = (contentRatings: Array<string>): ?Array<React.Element<'img'>> => {
    if (!contentRatings) return null;
    return contentRatings.map((rating, index) => (<img
      alt={CONTENT_RATING_DESCRIPTIONS[rating]}
      key={index} src={`/assets/ratings/${rating.toLowerCase()}.png`}
      title={CONTENT_RATING_DESCRIPTIONS[rating]}
      className="rating-icon"
    />));
  };

  render(): React$Element<'div'> {
    return (
      <div>
        {this.ageRatingIcon(this.props.ageRating)}
        {this.contentRatingIcons(this.props.contentRatings)}
      </div>
    );
  }
}

export default AgeRatings;
