// @flow
import $ from 'jquery';

const token = $('meta[name="csrf-token"]').attr('content');
let npoEventHandler = null;
const npoEvents = {};
const DEBUG = !!process.env.DEBUG || false;

export function fetchPomsData(mid: string, cb: any): void {
  $.ajax({
    url: `https://e.omroep.nl/metadata/${mid}`,
    dataType: 'jsonp',
    success: cb
  });
}

export function fetchPlayer(mid: string, params: Object): Promise<Object> {

  const fetchParams = new URLSearchParams({
    body: JSON.stringify({ ...params, mid })
  }).toString();

  const url = '/npo_player?' + fetchParams;

  return fetch(url)
    .then(res => res.json())
    .catch(error => new Error(error));
}

function handler(event: Object): void {
  try {
    const parsedEvent = JSON.parse(event.data);
    if (npoEvents[parsedEvent.playerId][parsedEvent.event]) {
      if (DEBUG) { console.log(parsedEvent) }
      npoEvents[parsedEvent.playerId][parsedEvent.event](parsedEvent);
    }
  } catch (e) {
    if (!event.data === undefined && !event.data.startsWith('ima') && !event.data.startsWith('get')) {
      console.warn(e);
    }
  }
}

export function addHandler(playerId: string, eventName: string, callback: Function): void {
  if (npoEventHandler === null) {
    npoEventHandler = window.addEventListener('message', handler, false);
  }
  if (DEBUG) { console.log("Handler added: " + eventName); }
  npoEvents[playerId] = { ...npoEvents[playerId], [eventName]: callback };
}

export function removeHandlers(playerId: string): void {
  if (DEBUG) { console.log("Remove handlers") }
  Object.keys(npoEvents[playerId]).forEach((key) => {
    window.removeEventListener('message', npoEvents[playerId][key]);
  });
}

export function loadMid(playerId: string, mid: string): void {
  const wrapper = document.getElementById(playerId);
  if (wrapper) {
    const player = ((wrapper.querySelector('iframe'): any): HTMLIFrameElement);
    player.contentWindow.postMessage(JSON.stringify({ event: 'NPO_LOADASSET', mediaId: mid }), '*');
  }
}

export function addPlayer(embedCode: string, id: string, mid: string, playerId: string): void {
  const range = document.createRange();
  const container = document.getElementById(id);
  if (container) {
    range.setStart(container, 0);
    container.appendChild(range.createContextualFragment(embedCode));
  }
}
