// @flow

import React from 'react';
import { components } from './utils/row';
import type { Row, CardContent, Zapper } from './types';
import CTA from './Cta';
import Header from './Header';

type Props = {
  rows: Array<Row<CardContent>>,
  header?: {
    coverImage: ?string,
    customHeaderText: ?string,
    customHeaderMid: ?string,
    namespace: ?string,
    title: ?string,
  },
  zapper?: Zapper
}

const isHeaderValid = (props: Props) => (
  props.header && props.header.namespace !== "programmas" && props.header.coverImage && !props.header.coverImage.match("missing.png")
)

const Subsite = (props: Props) => {
  const rows = props.rows.sort((a, b) => a.position - b.position);
  return (
    <div>
      {isHeaderValid(props) && <Header {...props.header } />}
      {props.zapper && <CTA {...props.zapper } />}
      {rows.map((row, index) =>
        React.createElement(components[row.component], { ...row, key: index })
      )}
    </div>
  );
};


export default Subsite;
