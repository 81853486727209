// @flow

import React from 'react';
import { find, reduce } from 'lodash';
import type { Question, Result as ResultType } from './types';

type Props = {
  questions: Question[],
  results: ResultType[],
  onRestart: Function,
};

const Result = (props: Props) => {
  const score = reduce(props.questions, ((memo, q) => memo + q.score), 0);
  const result = find(props.results, r =>
    r.lowerBound <= score && r.upperBound >= score,
  );

  return (
    <div>
      <div className="heading heading--green heading--xxs u-margin-top">Uitslag</div>
      <div className="rich-text">
        {result && result.description}
      </div>
      <button onClick={props.onRestart} className="btn btn--grey btn--s u-margin-top">
        Opnieuw beginnen
      </button>
    </div>
  );
};

export default Result;
