// @flow

import $ from 'jquery';
import React from 'react';
import Quiz from './Quiz';
import type { QuizContent, Question } from './types';

type State = {
  questionIndex: number,
  questions: Question[],
};

export default class QuizContainer extends React.Component<QuizContent, State> {
  constructor(props: any): void {
    super(props);
    this.state = {
      questionIndex: 0,
      questions: this.props.questions
    };
  }

  onSelect = (question: any, option: any): void => {
    question.options.forEach(o => o.selected = false); // eslint-disable-line no-param-reassign
    question.score = option.score; // eslint-disable-line no-param-reassign
    option.selected = true; // eslint-disable-line no-param-reassign
    this.forceUpdate();
  };

  onNextQuestion = (): void => {
    if (this.state.questionIndex === 0) {
      $.ajax({
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        url: this.props.url,
        method: 'POST'
      });
    }
    this.setState({
      questionIndex: this.state.questionIndex + 1
    });
  };

  onRestart = (): void => {
    this.state.questions.forEach((q) => {
      q.score = undefined; // eslint-disable-line no-param-reassign
      q.options.forEach(option => option.selected = false); // eslint-disable-line no-param-reassign
    });

    this.setState({
      questionIndex: 0
    });
  };

  render(): any {
    return (
      <Quiz
        {...this.props}
        questionIndex={this.state.questionIndex}
        questions={this.state.questions}
        onSelect={this.onSelect}
        onNextQuestion={this.onNextQuestion}
        onRestart={this.onRestart}
      />
    );
  }
}
